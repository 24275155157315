import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComponenteService {

  constructor(private afs: AngularFirestore) { }

  // Componentes
  getComponentes() {
    return this.afs.collection('componentes', ref => ref.orderBy('displayName')).snapshotChanges();
  }
  addComponente(data: any) {
    this.afs.collection('componentes').add(data);
  }
  updateComponente(id: string, data: any) {
    this.afs.doc('componentes/' + id).update(data);
  }
  deleteComponente(userId: string) {
    this.afs.doc('componentes/' + userId).delete();
  }
  // Equipos
  addEquipo(comp: string, data: any) {
    this.afs.collection('componentes').doc(comp).update({ equipos: data });
  }
  updateEquipo(id: string, data: any) {
    this.afs.collection('componentes').doc(id).update({ equipos: data });
  }
  // Preguntas / Parámetros de revisión
  addParametro(id: string, data: any) {
    this.afs.collection('componentes').doc(id).update({equipos: data});
  }
  updateParametro(id: string, data: any) {
    this.afs.collection('componentes').doc(id).update({equipos: data});
  }
  // Seguridad
  getSeguridad() {
    return this.afs.collection('seguridad').snapshotChanges();
  }
  updateSeccion(id: string, data: any) {
    this.afs.collection('seguridad').doc(id).update({equipos: data})
  }
}
