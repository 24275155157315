import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';


@Injectable({
  providedIn: 'root'
})
export class CalendarioService {

  constructor(private afs: AngularFirestore) { }

  getCalendar() {
    return this.afs.collection('calendario').snapshotChanges();
  }

  saveCalendar(id: string, data: any) {
    this.afs.collection('calendario').doc(id).update({dias: data})
  }
}
