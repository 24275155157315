import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth/auth.service';
import { NotificacionService } from '../services/notificacion/notificacion.service';
import { faBell, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { AngularFirestore } from '@angular/fire/firestore';
import { DomSanitizer } from '@angular/platform-browser';

import { Notificacion } from './../models/notificacion.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public userProfile: any;
  public avatarImage: any;
  public userLoggedIn: boolean;
  public numNotifications: number;
  public notificaciones: Notificacion[];
  faBell = faBell;
  faTimes = faTimes;

  constructor(
    private afs: AngularFirestore,
    public authService: AuthService,
    private sanitizer: DomSanitizer,
    private notificacionService: NotificacionService,
    private router: Router
  ) {
    this.userLoggedIn = false;
  }

  ngOnInit() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.afs.doc('users/' + user.uid).valueChanges().subscribe(user => {
          this.userProfile = user;
          this.avatarImage = this.sanitizer.bypassSecurityTrustUrl(this.userProfile.photoURL);
        })
      }
    });
    this.notificacionService
      .getNotifications()
      .subscribe(data => {
        this.notificaciones = data.map(e => {
          return {
            uid: e.payload.doc.id,
            ...e.payload.doc.data()
          } as Notificacion;
        })
        this.numNotifications = this.notificaciones.length;
      })
  }

  borrarNot(id: string) {
    this.notificacionService.deleteNotification(id);
  }

  goTo(reporte: string) {
    this.router.navigate(['/reporte/'+reporte]);
  }

}
