import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { CalendarEvent, CalendarMonthViewDay } from 'angular-calendar';
import { ConfirmDialogService } from "./../components/confirm-dialog/confirm-dialog.service";
import { ToastrService } from 'ngx-toastr';
import { CalendarioService } from '../services/calendario/calendario.service';
import { Calendario } from '../models/calendario.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CalendarioComponent implements OnInit {
  view: string = 'month';
  viewDate: Date = new Date();
  selectedMonthViewDay: CalendarMonthViewDay;
  events: CalendarEvent[] = [];
  selectedDays: any = [];
  calendario: Calendario[];
  idCalendar: string;
  refresh: Subject<any> = new Subject();

  constructor(private toastr: ToastrService, 
    private confirmDialogService: ConfirmDialogService,
    private calendarioService: CalendarioService) {
    }

  ngOnInit() {
    this.calendarioService.getCalendar().subscribe(data => {
      this.calendario = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Calendario;
      })
      this.idCalendar = this.calendario[0].uid;
      this.calendario[0].dias.forEach(day => {
        let dia = new Date(day.date.seconds * 1000);
        day.date = dia;
      })
      this.selectedDays = this.calendario[0].dias;
      console.log("BD", this.selectedDays)
      this.refresh.next();
    });
  }

  clickDay(day: CalendarMonthViewDay) {
    this.selectedMonthViewDay = day;
    if (this.selectedMonthViewDay.isFuture) {
      const selectedDateTime = this.selectedMonthViewDay.date.getTime();
      const dateIndex = this.selectedDays.findIndex(selectedDay => selectedDay.date.getTime() === selectedDateTime);
      if (dateIndex > -1) {
        delete this.selectedMonthViewDay.cssClass;
        this.selectedDays.splice(dateIndex, 1);
      } else {
        this.selectedDays.push(this.selectedMonthViewDay);
        day.cssClass = 'cal-day-selected';
        this.selectedMonthViewDay = day;
      }
    } else if (this.selectedMonthViewDay.isToday) {
      this.toastr.error('No se pueden crear reportes hoy.');
    } else {
      this.toastr.error('No se pueden crear reportes en el pasado.');
    }
  }

  beforeMonthViewRender({ body }: { body: CalendarMonthViewDay[] }): void {
    body.forEach(day => {
      if (this.selectedDays.some(selectedDay => selectedDay.date.getTime() === day.date.getTime())) {
        day.cssClass = 'cal-day-selected';
      }
    });
  }

  saveDays() {
    this.selectedDays.forEach(day => {
      let dia = new Date(day.date);
      day.date = dia;
    })
    let data = this.selectedDays;
    this.calendarioService.saveCalendar(this.idCalendar, data)
    this.toastr.success('Fechas guardadas.');
  }
}
