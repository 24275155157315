import { Component, OnInit, ElementRef, ViewChild, LOCALE_ID, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { PlantaService } from './../services/planta/planta.service';
import { UserService } from '../services/user/user.service';
import { ComponenteService } from '../services/componente/componente.service';
import { ReporteService } from '../services/reporte/reporte.service';
import { Planta } from './../models/planta.model';
import { User } from '../models/user.model';
import { Componente } from '../models/componente.model';
import { ListaReportes } from '../models/lista-reportes.model';
import { finalize } from 'rxjs/operators';
declare var $: any;

import { faPlus, faPen, faTimes, faCloudUploadAlt, faAngleRight, faLockOpen } from '@fortawesome/free-solid-svg-icons';

import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ConfirmDialogService } from "./../components/confirm-dialog/confirm-dialog.service"; 
import { UploadService } from "./../services/upload/upload.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-plantas',
  templateUrl: './plantas.component.html',
  styleUrls: ['./plantas.component.css']
})
export class PlantasComponent implements OnInit {
  public newPlantaForm: FormGroup;
  public editPlantaForm: FormGroup;
  public assignForm: FormGroup;
  public assignArray: FormArray;
  plantas: Planta[];
  users: User[];
  componentes: Componente[];
  reportes: ListaReportes[];
  arrayUsersId: Array<string>;
  faPlus = faPlus;
  faPen = faPen;
  faTimes = faTimes;
  faAngleRight = faAngleRight;
  faCloudUploadAlt = faCloudUploadAlt;
  faLockOpen = faLockOpen;
  public showSpinner: boolean;
  public showAssignCompSpinner: boolean;
  public showAssignComponentsSpinner: boolean;
  public newPlanta: boolean;
  public modalTitle: string;
  public filePath: string;
  public file: any;
  urlFile: Observable<string>;
  editPlantaFormImg: string;
  plantaToEdit: string;
  @ViewChild('closeBtn') closeBtn: ElementRef;

  constructor(private plantaService: PlantaService,
    private userService: UserService,
    private componentService: ComponenteService,
    private formBuilder: FormBuilder, 
    private confirmDialogService: ConfirmDialogService,
    private uploadService: UploadService,
    private reporteService: ReporteService,
    private toastr: ToastrService,
    @Inject(LOCALE_ID) private locale: string ) { 
      this.filePath = "";
      this.file = "";
      this.showSpinner = false;
      this.showAssignCompSpinner = false;
      this.showAssignComponentsSpinner = false;

      this.newPlantaForm = this.formBuilder.group({
        displayName: [ '', Validators.compose([Validators.required]) ],
        address: [ '', Validators.compose([Validators.required]) ],
        photoURL: [ '', Validators.compose([Validators.required]) ],
      });
      this.editPlantaForm = this.formBuilder.group({
        displayName: [ '', Validators.compose([Validators.required]) ],
        address: [ '', Validators.compose([Validators.required]) ],
        photoURL: [ '', Validators.compose([Validators.nullValidator]) ],
      });
    }

  ngOnInit() {
    this.plantaService.getPlantas().subscribe(data => {
      this.plantas = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Planta;
      })
    });
    this.userService.getUsers().subscribe(data => {
      this.users = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as User;
      })
    });
    this.componentService.getComponentes().subscribe(data => {
      this.componentes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Componente;
      })
    });
    this.reporteService.getReportesPlantas().subscribe(data => {
      this.reportes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
    });
  }

  // Change photo
  photoChange(event) {
    const id = this.randomString(14);
    this.file = event.target.files[0];
    this.filePath = "plantas/"+id;
  }

  // New Planta
  clickNewPlanta() {
    this.newPlanta = true;
    this.modalTitle = "Nueva Planta";
  }

  onNewPlantaFormSubmit() {
    this.showSpinner = true;
    let ref = this.uploadService.getRefUpload(this.filePath);
    let task = this.uploadService.uploadFile(this.filePath, this.file);
    
    task.snapshotChanges().subscribe(
      data => {}, 
      e => { console.log(e); }, 
      () => {
        ref.getDownloadURL().subscribe(
          (url)=> { this.urlFile = url; },
          (e) => { 
            this.toastr.error('Error', e);
          }, 
          ()=> {
            // Create Planta
            let data = {
              displayName: this.newPlantaForm.value.displayName,
              address: this.newPlantaForm.value.address,
              photoURL: this.urlFile,
              reporte: { enviado: false, fecha: new Date()},
              score: 0,
              assign: [],
              assignuid: [],
              components: []
            }
            this.plantaService.addPlanta(data);
            this.toastr.success('', 'Planta agregada');
            // Close modal
            this.closeBtn.nativeElement.click();
          }
        )
    })
  }

  // Edit Planta
  clickEditPlanta(id: string) {
    this.newPlanta = false;
    this.modalTitle = "Editar Planta";
    let subscription = this.plantaService.getPlanta(id).subscribe((planta: Planta) => {
      this.editPlantaFormImg = planta.photoURL;
      this.editPlantaForm.patchValue({
        displayName: planta.displayName,
        address: planta.address,
      });
      this.plantaToEdit = id;
      subscription.unsubscribe(); 
    })
  }

  onEditPlantaFormSubmit() {
    this.showSpinner = true;
    // Upload Image
    if (this.filePath != "") {
      let ref = this.uploadService.getRefUpload(this.filePath);
      let task = this.uploadService.uploadFile(this.filePath, this.file);
      task.snapshotChanges().pipe(finalize(() => { 
        ref.getDownloadURL().subscribe(
        (url)=> { this.urlFile = url; },
          ()=> {}, 
          ()=> {
            // Save user in Database
            let data = {
              displayName: this.editPlantaForm.value.displayName,
              address: this.editPlantaForm.value.address,
              photoURL: this.urlFile,
            }
            this.plantaService.updatePlanta(this.plantaToEdit, data);
            this.toastr.success('', 'Usuario editado');
            // Close modal
            this.closeBtn.nativeElement.click();
          } 
        )
      })).subscribe();
    } else {
      // Save user in Database
      let data = {
        displayName: this.editPlantaForm.value.displayName,
        address: this.editPlantaForm.value.address,
      }
      this.plantaService.updatePlanta(this.plantaToEdit, data);
      this.toastr.success('', 'Usuario editado');
      // Close modal
      this.closeBtn.nativeElement.click();
    }
  }

  // Delete Planta
  delete(id: string) {
    this.confirmDialogService.confirmThis("¿Segur@ que desea borrar esta planta?", 
      () => this.deleteConfirmed(id), function () {}, "Borrar", "Cancelar"
    )
  }
  deleteConfirmed(plantaId: string) {
    this.plantaService.deletePlanta(plantaId);
    this.toastr.success('', 'Planta borrada');
  }

  // Assign encargados
  onAssignFormSubmit (id: string) {
    var planta = this.plantas.find(p => p.uid === id);
    var restarPlantas = [];
    var sumarPlantas = [];
    var assignInputs = $("#collapse"+id+" form :input");
    var arrayAssign = [];
    var arrayAssignUid = []; 
    assignInputs.each(function() {
      if ($(this).is(":checked")) {
        arrayAssign.push({"displayName": $(this).attr("name"), "id": $(this).attr("id")})
        arrayAssignUid.push($(this).attr("id"));
        if (planta.assignuid.indexOf($(this).attr("id")) == -1) {
          sumarPlantas.push($(this).attr("id"));
        }
      } else {
        if (planta.assignuid.indexOf($(this).attr("id")) != -1) {
          restarPlantas.push($(this).attr("id"));
        }
      }
    })
    let data = {
      assign: arrayAssign,
      assignuid: arrayAssignUid
    }
    this.plantaService.updatePlanta(id, data);
    $("#collapse"+id).collapse('hide');
    sumarPlantas.forEach(u => {
      this.userService.sumarPlanta(u);
    })
    restarPlantas.forEach(u => {
      this.userService.restarPlanta(u);
    })
  }

  // Assign components
  onComponentsFormSubmit(id: string){
    let assignInputs = $("#collapseP"+id+" form :input");
    var arrayAssign = [];
    assignInputs.each(function() {
      if ($(this).is(":checked")) {
        arrayAssign.push({"displayName": $(this).attr("name"), "id": $(this).attr("id")})
      }
    })
    let data = {
      components: arrayAssign
    }
    console.log(data)
    this.plantaService.updatePlanta(id, data);
    $("#collapseP"+id).collapse('hide');
  }

  // Utils
  randomString(length) {  
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for(var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  dismissModal(){
    this.filePath = "";
    this.file = "";
    this.showSpinner = false;
    this.newPlantaForm.reset();
  }
  dismissAssignCompModal() {
    this.showAssignCompSpinner = false;
  }
  isAssigned(id: string) {
    return false;
  }
  openPlanta(id: string) {
    console.log("Open planta", id);

    let data = {
      reporte: { enviado: false, fecha: new Date() }
    }
    this.plantaService.updatePlanta(id, data);
    this.toastr.success('', 'Reporte abierto');
  }

}
