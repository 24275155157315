import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlantaService {

  constructor(private afs: AngularFirestore) { }

  getPlantas() {
    return this.afs.collection('plantas', ref => ref.orderBy('displayName')).snapshotChanges();
  }

  addPlanta(data: any) {
    this.afs.collection('plantas').add(data);
  }

  getPlanta(id: string): Observable<{}> {
    return this.afs.collection('plantas').doc(id).valueChanges();
  }

  updatePlanta(id: string, data: any) {
    this.afs.doc('plantas/' + id).update(data);
  }

  deletePlanta(userId: string) {
    this.afs.doc('plantas/' + userId).delete();
  }
}
