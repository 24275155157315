import { Injectable } from '@angular/core';

import { Router } from  "@angular/router";
import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";

import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { switchMap} from 'rxjs/operators';

import { ToastrService } from 'ngx-toastr';
import { User } from '../../models/user.model';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
	user: Observable<User>;
	showSpinner: boolean;
	userLogged: any;

  	constructor(private toastr: ToastrService, public  afAuth:  AngularFireAuth, public  router:  Router, private afs: AngularFirestore) {
  	  this.showSpinner = false;
  	  //// Get auth data, then get firestore user document || null
      this.user = this.afAuth.authState.pipe(
        switchMap(user => {
          if (user) {
            return this.afs.doc<User>(`users/${user.uid}`).valueChanges()
          } else {
            return of(null)
          }
        })
      )
  	}

	public login(email:  string, password:  string) {
		this.showSpinner = true;
			//this.afAuth.auth.setPersistence(firebase.auth.Auth.Persistence.SESSION)
	    this.afAuth.auth.signInWithEmailAndPassword(email, password)
	      .then((credential) => {
	      	this.afs.doc('users/'+credential.user.uid).valueChanges().subscribe(user => {
	      		this.userLogged = user;
	      		//this.updateUserData(credential.user);
	      		if (this.userLogged.type == "administrador" || this.userLogged.type == "supervisor" ) {
	        		localStorage.setItem('user', JSON.stringify(credential.user));
	        		this.showSpinner = false;
	        		this.router.navigate(['']);
	      		} else {
	      			//alert("Error! No está autorizado para acceder.");
	      			this.showSpinner = false;
	      			this.toastr.error('Error de autenticación', 'Su usuario no está autorizado para acceder');
	      		}
	      	})
	      })
	      .catch((error) => {
	      	//alert("Error! "  +  error.message);
	      	this.showSpinner = false;
	      	this.toastr.error('Error de autenticación', 'Usuario o contraseña incorrectos.');
	      })
	}

	private updateUserData(user) {
	    const userRef: AngularFirestoreDocument<any> = this.afs.doc('users/'+user.uid);
	    const data: User = {
	      uid: user.uid,
	      email: user.email,
	      displayName: user.displayName,
	      photoURL: user.photoURL,
	    }
	    return userRef.set(data, { merge: true })
	}

	async logout(){
	    await this.afAuth.auth.signOut();
	    localStorage.removeItem('user');
	    this.router.navigate(['login/']);
	}

	get isLoggedIn(): boolean {
	    const  user  =  JSON.parse(localStorage.getItem('user'));
	    return  user  !==  null;
	}
}
