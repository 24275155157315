import { Component, OnInit } from '@angular/core';
import { ListaReportes } from '../../models/lista-reportes.model';
import { ReporteService } from '../../services/reporte/reporte.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Planta } from './../../models/planta.model';
import { PlantaService } from './../../services/planta/planta.service';

@Component({
  selector: 'app-observaciones',
  templateUrl: './observaciones.component.html',
  styleUrls: ['./observaciones.component.css']
})
export class ObservacionesComponent implements OnInit {
  public informeForm: FormGroup;
  plantas: Planta[];
  public months: Array<string>;
  public years: Array<number>;
  public showSpinner: boolean;
  public reportes: any;
  public params: any;
  public rep: Array<any>;
  public observaciones: any;
  public fechas: any;
  public gData: any[];
  public graph: boolean;

  constructor(private plantaService: PlantaService,
    private formBuilder: FormBuilder,
    private reporteService: ReporteService,
    ) {
      this.informeForm = this.formBuilder.group({
        planta: ['', Validators.compose([Validators.required])],
        month: ['', Validators.compose([Validators.required])],
        year: ['', Validators.compose([Validators.required])],
      });
      this.showSpinner = false;
      var date = new Date();
      var currentYear = date.getFullYear();
      //set values for year dropdown
      this.years = [];
      for (var i = 0; i <= 10; i++) {
        this.years.push(currentYear - i);
      }
      //set values for month dropdown
      this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
      // Initialise rep
      this.rep = [];
      this.observaciones = [];
      this.fechas = [];
     }

  ngOnInit() {
    this.plantaService.getPlantas().subscribe(data => {
      this.plantas = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Planta;
      })
    });
  }

  onInformeFormSubmit() {
    // Reset variables
    this.reportes = [];
    this.observaciones = [];
    this.fechas = [];
    // Get new graph
    this.showSpinner = true;
    this.params = {
      planta: this.informeForm.value.planta.id,
      month: this.informeForm.value.month,
      year: this.informeForm.value.year,
    }
    this.reporteService.getReporteInforme(this.params).subscribe(data => {
      this.reportes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
      let rs = [];
      this.reportes.forEach((reporte, index) => {
        rs.push(reporte.reporte)
        this.fechas.push(reporte.date)
      })
      this.reporteService.getConsolidado(rs).subscribe(rep => {
        let obs = [];
        rep.forEach(r => {
          obs.push(r.observaciones)
        })
        this.fechas.forEach((f, index) => {
          this.observaciones.push({fecha: f, ob: obs[index]})
        })
        //console.log("Consolidado: ", this.observaciones)
        this.showSpinner = false;
      })
    })
  }

}
