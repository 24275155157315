import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { ComponenteService } from './../services/componente/componente.service';
import { Componente } from '../models/componente.model';
declare var $: any;

import { faPlus, faPen, faTimes } from '@fortawesome/free-solid-svg-icons';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogService } from "./../components/confirm-dialog/confirm-dialog.service"; 
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-componentes',
  templateUrl: './componentes.component.html',
  styleUrls: ['./componentes.component.css']
})
export class ComponentesComponent implements OnInit {
  componentes: Componente[];
  seguridad: Componente[];
  faPlus = faPlus;
  faPen = faPen;
  faTimes = faTimes;
  public newCompForm: FormGroup;
  public newEqForm: FormGroup;
  public newParamForm: FormGroup;
  public newSecForm: FormGroup;
  public newParamSecForm: FormGroup;
  public showSpinner: boolean;
  @ViewChild('closeBtnComp') closeBtnComp: ElementRef;
  @ViewChild('closeBtnEq') closeBtnEq: ElementRef;
  @ViewChild('closeBtnParam') closeBtnParam: ElementRef;
  @ViewChild('closeBtnSec') closeBtnSec: ElementRef;
  @ViewChild('closeBtnParamSec') closeBtnParamSec: ElementRef;
  public compAEditar: any;
  public eqAEditar: any;
  public seccionAEditar: any;
  public compSelected: boolean;
  public eqSelected: boolean;
  public seccionSelected: boolean;

  constructor(private componenteService: ComponenteService,
    private formBuilder: FormBuilder, 
    private confirmDialogService: ConfirmDialogService,
    private toastr: ToastrService) { 
      this.newCompForm = this.formBuilder.group({
        displayName: [ '', Validators.compose([Validators.required]) ],
      });
      this.newEqForm = this.formBuilder.group({
        displayName: [ '', Validators.compose([Validators.required]) ],
      });
      this.newParamForm = this.formBuilder.group({
        pregunta: [ '', Validators.compose([Validators.required]) ],
      });
      this.newSecForm = this.formBuilder.group({
        displayName: [ '', Validators.compose([Validators.required]) ],
      });
      this.newParamSecForm = this.formBuilder.group({
        pregunta: [ '', Validators.compose([Validators.required]) ],
      });

      this.compAEditar = "";
      this.eqAEditar = "";
      this.seccionAEditar = "";
      this.compSelected = false;
      this.eqSelected = false;
      this.seccionSelected = false;
    }

  ngOnInit() {
    this.componenteService.getComponentes().subscribe(data => {
      this.componentes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Componente;
      })
    });
    this.componenteService.getSeguridad().subscribe(data => {
      this.seguridad = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Componente;
      })
    });
  }
  // General
  changeComp(id: string, index: number) {
    this.compAEditar = { id: id, index: index }
    this.compSelected = true;
  }
  changeEquipo(indexEq: number) {
    this.eqAEditar = { index: indexEq }
    this.eqSelected = true;
  }
  changeSec(id: string, index: number) {
    this.seccionAEditar = { id: id, index: index };
    this.seccionSelected = true;
  }
  // Componentes
  onNewCompFormSubmit() {
    this.showSpinner = true;
    let data = {
      displayName: this.newCompForm.value.displayName,
    }
    this.componenteService.addComponente(data);
    this.toastr.success('', 'Componente agregado');
    // Close modal
    this.closeBtnComp.nativeElement.click();
  }
  editComponente(id: string) {
    let editInput = $("#collapse"+id+" form :input").val();
    let data = {
      displayName: editInput
    }
    this.componenteService.updateComponente(id, data);
    $("#collapse"+id).collapse('hide');
    this.toastr.success('', 'Componente editado');
  }
  borrarComponente(id: string) {
    this.confirmDialogService.confirmThis("¿Está segur@ que desea borrar este componente?", 
      () => this.deleteConfirmedComp(id), function () {}, "Borrar", "Cancelar"
    )
  }
  deleteConfirmedComp(compId: string) {
    this.componenteService.deleteComponente(compId);
    this.toastr.success('', 'Componente borrado');
  }
  // Equipos
  onNewEqFormSubmit() {
    this.showSpinner = true;
    let data = {
      displayName: this.newEqForm.value.displayName,
    }
    let comp = this.componentes[this.compAEditar.index];
    let equipos: any;
    if (comp.equipos) { equipos = comp.equipos; } else { equipos = []; }
    equipos.push(data);
    this.componenteService.addEquipo(this.compAEditar.id, equipos);
    this.toastr.success('', 'Equipo agregado');
    // Close modal
    this.closeBtnEq.nativeElement.click();
  }
  editEquipo(id: string, index: number) {
    let eq  = Object.assign([], this.componentes[this.compAEditar.index].equipos);
    eq.splice(index, 1);
    let editInput = $("#collapse"+id+"-"+index+" form :input").val();
    let data = {
      displayName: editInput
    }
    eq.push(data);
    this.componenteService.updateEquipo(id, eq);
    $("#collapse"+id+"-"+index).collapse('hide');
    this.toastr.success('', 'Equipo editado');
  }
  borrarEquipo(index: number) {
    this.confirmDialogService.confirmThis("¿Está segur@ que desea borrar este equipo?", 
      () => this.deleteConfirmedEq(index), function () {}, "Borrar", "Cancelar"
    )
  }
  deleteConfirmedEq(eqIndex: number) {
    let eq  = Object.assign([], this.componentes[this.compAEditar.index].equipos);
    eq.splice(eqIndex, 1);
    this.componenteService.updateEquipo(this.compAEditar.id, eq);
    this.toastr.success('', 'Equipo borrado');
  }
  // Preguntas
  onNewParamFormSubmit() {
    this.showSpinner = true;
    let comp = this.componentes[this.compAEditar.index];
    let eq = this.eqAEditar;
    let equipos = comp.equipos;
    if (!equipos[eq.index].preguntas) { comp.equipos[eq.index].preguntas = [] }
    comp.equipos[eq.index].preguntas.push(this.newParamForm.value.pregunta)
    this.componenteService.addParametro(this.compAEditar.id, equipos);
    this.toastr.success('', 'Parámetro de revisión agregado');
    // Close modal
    this.closeBtnParam.nativeElement.click();
  }
  editPregunta(compIndex: string, eqIndex: number,  paramIndex: number) {
    let comp = this.componentes[this.compAEditar.index];
    let equipos = comp.equipos;
    let preg  = Object.assign([], this.componentes[this.compAEditar.index].equipos[this.eqAEditar.index].preguntas);
    preg.splice(paramIndex, 1);
    let editInput = $("#collapse"+compIndex+"-"+eqIndex+"-"+paramIndex+" form :input").val();
    preg.push(editInput)
    equipos[eqIndex].preguntas = preg;
    this.componenteService.updateParametro(compIndex, equipos);
    this.toastr.success('', 'Parámetro editado');
  }
  borrarPregunta(index: number) {
    this.confirmDialogService.confirmThis("¿Está segur@ que desea borrar este parámetro?", 
      () => this.deleteConfirmedParam(index), function () {}, "Borrar", "Cancelar"
    )
  }
  deleteConfirmedParam(paramIndex: number) {
    let comp = this.componentes[this.compAEditar.index];
    let eq = this.eqAEditar;
    let equipos = comp.equipos;
    let preg  = Object.assign([], this.componentes[this.compAEditar.index].equipos[this.eqAEditar.index].preguntas);
    preg.splice(paramIndex, 1);
    equipos[eq.index].preguntas = preg;
    this.componenteService.updateParametro(this.compAEditar.id, equipos);
    this.toastr.success('', 'Parámetro borrado');
  }

  // Seguridad
  onNewSecFormSubmit() {
    this.showSpinner = true;
    let seccion  = Object.assign([], this.seguridad[0].equipos);
    let data = {
      displayName: this.newSecForm.value.displayName,
    }
    seccion.push(data);
    this.componenteService.updateSeccion(this.seguridad[0].uid, seccion);
    this.toastr.success('', 'Componente agregado');
    // Close modal
    this.closeBtnSec.nativeElement.click();
  }
  onNewParamSecFormSubmit() {
    this.showSpinner = true;
    let seccion  = Object.assign([], this.seguridad[0].equipos);
    let preguntas = seccion[this.seccionAEditar.index].preguntas;
    if (!preguntas) { preguntas = [] }
    preguntas.push(this.newParamSecForm.value.pregunta)
    seccion[this.seccionAEditar.index].preguntas = preguntas;
    this.componenteService.updateSeccion(this.seguridad[0].uid, seccion);
    this.toastr.success('', 'Parámetro de revisión agregado');
    // Close modal
    this.closeBtnParamSec.nativeElement.click();
  }
  editSeccion(id: string, index: number) {
    let seccion  = Object.assign([], this.seguridad[0].equipos);
    seccion.splice(index, 1);
    let editInput = $("#collapseSeccion"+index+" form :input").val();
    let data = {
      displayName: editInput
    }
    seccion.push(data);
    this.componenteService.updateSeccion(id, seccion);
    $("#collapse"+index).collapse('hide');
    this.toastr.success('', 'Sección editada');
  }
  borrarSeccion(index:number) {
    this.confirmDialogService.confirmThis("¿Está segur@ que desea borrar esta sección?", 
      () => this.deleteConfirmedSeccion(index), function () {}, "Borrar", "Cancelar"
    )
  }
  deleteConfirmedSeccion(index: number) {
    let equipos  = Object.assign([], this.seguridad[0].equipos);
    equipos.splice(index, 1);
    this.componenteService.updateSeccion(this.seccionAEditar.id, equipos);
    this.toastr.success('', 'Sección borrada');
  }
  editPreguntaSec(sec: number, index: number) {
    let seccion  = Object.assign([], this.seguridad[0].equipos);
    seccion[sec].preguntas.splice(index, 1);
    let editInput = $("#collapseSecPreg"+sec+"-"+index+" form :input").val();
    seccion[sec].preguntas.push(editInput);
    this.componenteService.updateSeccion(this.seccionAEditar.id, seccion);
    $("#collapseSecPreg"+sec+"-"+index).collapse('hide');
    this.toastr.success('', 'Parámetro de revisión agregado');
  }
  borrarPreguntaSec(sec: number, index: number) {
    this.confirmDialogService.confirmThis("¿Está segur@ que desea borrar este parámetro de revisión?", 
      () => this.deleteConfirmedSeccionPreg(sec, index), function () {}, "Borrar", "Cancelar"
    )
  }
  deleteConfirmedSeccionPreg(sec: number, index: number) {
    let seccion  = Object.assign([], this.seguridad[0].equipos);
    seccion[sec].preguntas.splice(index, 1);
    this.componenteService.updateSeccion(this.seccionAEditar.id, seccion);
    this.toastr.success('', 'Parámetro de revisión borrado');
  }

  // Utils
  dismissModal(){
    this.newCompForm.reset()
    this.newEqForm.reset()
    this.newParamForm.reset()
    this.showSpinner = false;
  }
}
