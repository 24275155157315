import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CumplimientoService {

  constructor(private afs: AngularFirestore) { }

  getCumplimiento(id: string) {
    return this.afs.collection('cumplimiento').doc(id).valueChanges();
  }

  addCumplimiento(id: string, data: any) {
    this.afs.collection('cumplimiento').doc(id).set(data);
  }
}
