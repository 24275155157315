import { Component, OnInit } from '@angular/core';
import { faHome, faTint, faUser, faLayerGroup, faFileContract, faHistory, faCalendarDay  } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
	faHome = faHome;
	faTint = faTint;
	faUser = faUser;
	faLayerGroup = faLayerGroup;
  faFileContract = faFileContract;
  faHistory = faHistory;
  faCalendarDay = faCalendarDay;

  constructor() { }

  ngOnInit() {
  }

}
