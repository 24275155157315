import { Component, OnInit } from '@angular/core';
import { ListaReportes } from '../../models/lista-reportes.model';
import { ReporteService } from '../../services/reporte/reporte.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Planta } from './../../models/planta.model';
import { PlantaService } from './../../services/planta/planta.service';

@Component({
  selector: 'app-icomponentes',
  templateUrl: './icomponentes.component.html',
  styleUrls: ['./icomponentes.component.css']
})
export class IcomponentesComponent implements OnInit {
  public informeForm: FormGroup;
  plantas: Planta[];
  public months: Array<string>;
  public years: Array<number>;
  public showSpinner: boolean;
  public reportes: any;
  public params: any;
  public rep: Array<any>;
  public consolidado: any;
  public gData: any[];
  public graph: boolean;
  // Graph options
  view: any[] = [700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Componente';
  showYAxisLabel = true;
  yAxisLabel = '% de cumplimiento';
  colorScheme = {
    domain: ['#0099CD']
  };
  showGridLines = true;

  constructor(
    private plantaService: PlantaService,
    private formBuilder: FormBuilder,
    private reporteService: ReporteService,
  ) {
    this.informeForm = this.formBuilder.group({
      planta: ['', Validators.compose([Validators.required])],
      month: ['', Validators.compose([Validators.required])],
      year: ['', Validators.compose([Validators.required])],
    });
    this.showSpinner = false;
    var date = new Date();
    var currentYear = date.getFullYear();
    //set values for year dropdown
    this.years = [];
    for (var i = 0; i <= 10; i++) {
      this.years.push(currentYear - i);
    }
    //set values for month dropdown
    this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    // Initialise rep
    this.rep = [];
    this.consolidado = [];
    this.gData = [];
    this.graph = false;
    //Object.assign(this, this.gData)
    this.gData = [...this.gData]
  }

  ngOnInit() {
    this.plantaService.getPlantas().subscribe(data => {
      this.plantas = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Planta;
      })
    });
  }

  onInformeFormSubmit() {
    // Reset variables
    this.consolidado = [];
    this.gData = [];
    this.gData = [...this.gData]
    // Get new graph
    this.showSpinner = true;
    this.params = {
      planta: this.informeForm.value.planta.id,
      month: this.informeForm.value.month,
      year: this.informeForm.value.year,
    }
    this.reporteService.getReporteInforme(this.params).subscribe(data => {
      this.reportes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
      let rs = [];
      this.reportes.forEach((reporte, index) => {
        rs.push(reporte.reporte)
      })
      this.reporteService.getConsolidado(rs).subscribe(rep => {
        rep.forEach(r => {
          let totalPreguntas = 0;
          let totalFallas = 0;
          r.componentes.forEach(c => {
            c.equipos.forEach(e => {
              e.preguntas.forEach(p => {
                totalPreguntas += 1;
                if (p.respuesta == 'false') {
                  totalFallas += 1;
                  //this.fallas.push({ componente: c.displayName, equipo: e.displayName, pregunta: p.pregunta })
                  //this.fallas.push(p.pregunta)
                }
              })
            })
            this.consolidado.push({ componente: c.displayName, preguntas: totalPreguntas, fallas: totalFallas })
          })
        })
        this.gData = this.prepareData();  
        this.graph = true;
        this.showSpinner = false;
      })
    })
  }

  prepareData() {
    let data: any = [];
    do {
      let search = this.consolidado[0].componente;
      let totalFallas = 0;
      let totalPreguntas = 0;
      let componente = "";
      for (var j = 0; j < this.consolidado.length; j++) {
        if (this.consolidado[j].componente == search) {
          totalPreguntas += this.consolidado[j].preguntas;
          totalFallas += this.consolidado[j].fallas;
          componente = this.consolidado[j].componente;
          this.consolidado.splice(j, 1);
        } 
      }
      let val = 100 * (totalPreguntas - totalFallas) / totalPreguntas;
      data.push({ name: componente, value: val });
    } while (this.consolidado.length != 0)
    //console.log(data, this.consolidado)
    return data;
  }

  onSelect(event) {
    console.log(event);
  }

}
