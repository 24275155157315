import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private afs: AngularFirestore) { }

  getUsers() {
  	return this.afs.collection('users', ref => ref.orderBy('displayName')).snapshotChanges();
  }

  getOperarios() {
  	return this.afs.collection('users', ref => ref.where("type", "==", "operario").orderBy('displayName')).snapshotChanges();
  }

  addUser(id: string, data: any) {
    this.afs.collection('users').doc(id).set(data);
  }

  getUser(id: string): Observable<{}> {
    return this.afs.collection('users').doc(id).valueChanges();
  }

  updateUser(id: string, data: any) {
    this.afs.doc('users/' + id).update(data);
  }

  deleteUser(userId: string) {
    this.afs.doc('users/' + userId).delete();
  }

  sumarPlanta(id: string) {
    this.afs.collection('users').doc(id).get().subscribe(u => {
      let numPlantas = u.data().num_plantas;
      numPlantas = numPlantas + 1;
      this.afs.doc('users/' + id).update({num_plantas: numPlantas});
    })
  }

  restarPlanta(id: string) {
    this.afs.collection('users').doc(id).get().subscribe(u => {
      let numPlantas = u.data().num_plantas;
      numPlantas = numPlantas - 1;
      this.afs.doc('users/' + id).update({num_plantas: numPlantas});
    })
  }
}
