import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { UserService } from '../services/user/user.service';
import { User } from '../models/user.model';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { faUserPlus, faSearch, faFlask, faTimes, faPen, faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogService } from "./../components/confirm-dialog/confirm-dialog.service";
import { UploadService } from "./../services/upload/upload.service";
import { CumplimientoService } from "./../services/cumplimiento/cumplimiento.service";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  public newUserForm: FormGroup;
  public editUserForm: FormGroup;
  public newUser: boolean;
  users: User[];
  faUserPlus = faUserPlus;
  faSearch = faSearch;
  faFlask = faFlask;
  faTimes = faTimes;
  faPen = faPen;
  faCloudUploadAlt = faCloudUploadAlt;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @ViewChild('userList') userList: ElementRef;
  userToEdit: string;
  public modalTitle: string;
  public filePath: string;
  public file: any;
  urlFile: Observable<string>;
  editUserFormImg: string;
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    })
  };
  public newUserUid: string;
  public showSpinner: boolean;

  constructor(private userService: UserService,
    private formBuilder: FormBuilder,
    private confirmDialogService: ConfirmDialogService,
    private uploadService: UploadService,
    private http: HttpClient,
    private toastr: ToastrService,
    private cumplimientoService: CumplimientoService) {

    this.filePath = "";
    this.file = "";
    this.showSpinner = false;
    this.newUserForm = this.formBuilder.group({
      displayName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]+)$'), Validators.minLength(6), Validators.maxLength(25)])],
      phone: ['', Validators.compose([Validators.nullValidator])],
      type: ['operario', Validators.compose([Validators.required])],
      photoURL: ['', Validators.compose([Validators.required])]
    });
    this.editUserForm = this.formBuilder.group({
      displayName: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      password: ['', Validators.compose([Validators.nullValidator])],
      phone: ['', Validators.compose([Validators.nullValidator])],
      type: ['operario', Validators.compose([Validators.required])],
      photoURL: ['', Validators.compose([Validators.nullValidator])]
    });
  }

  ngOnInit() {
    this.userService.getUsers().subscribe(data => {
      this.users = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as User;
      })
    });
  }

  // New User
  clickNewUser() {
    this.newUser = true;
    this.modalTitle = "Nuevo Usuario";
  }
  onNewUserFormSubmit() {
    this.showSpinner = true;
    // Create user in auth
    let authData = {
      mail: this.newUserForm.value.email,
      pass: this.newUserForm.value.password,
      name: this.newUserForm.value.displayName
    }
    this.http.post<any>("https://us-central1-control-de-plantas.cloudfunctions.net/addUser", authData, this.httpOptions).subscribe(
      (result) => {
        this.newUserUid = result.uid;
        //console.log("Auth user created");
        // Upload Image
        let ref = this.uploadService.getRefUpload(this.filePath);
        let task = this.uploadService.uploadFile(this.filePath, this.file);
        task.snapshotChanges().pipe(finalize(() => {
          ref.getDownloadURL().subscribe(
            (url) => { this.urlFile = url; },
            () => { },
            () => {
              // Save user in Database
              let data = {
                displayName: this.newUserForm.value.displayName,
                email: this.newUserForm.value.email,
                phone: this.newUserForm.value.phone,
                photoURL: this.urlFile,
                type: this.newUserForm.value.type,
                uid: this.newUserUid,
                num_plantas: 0,
                reportes_completos: 0,
                reportes_no_enviados: 0
              }
              this.userService.addUser(this.newUserUid, data);
              this.toastr.success('', 'Usuario agregado');
              // Create registros de cumplimiento para Operarios
              if (this.newUserForm.value.type == "operario") {
                var date = new Date();
                var currentYear = date.getFullYear();
                var currentMonth = date.getMonth();
                let idcump = this.newUserUid + currentYear;
                let losMeses = [];
                for (let index = 0; index <= currentMonth; index++) {
                  losMeses.push({enviados: 0, programados: 0})
                }
                let datacump = { meses: losMeses, user: this.newUserUid, year: currentYear }
                this.cumplimientoService.addCumplimiento(idcump, datacump);
              }
              // Close modal
              this.closeBtn.nativeElement.click();
            }
          )
        })).subscribe();
      }
    )
  }
  photoChange(event) {
    const id = this.randomString(14);
    this.file = event.target.files[0];
    this.filePath = "avatars/" + id;
  }

  // Edit User
  clickEdit(id: string) {
    this.newUser = false;
    this.modalTitle = "Editar Usuario";
    let subscription = this.userService.getUser(id).subscribe((user: User) => {
      this.editUserFormImg = user.photoURL;
      this.editUserForm.patchValue({
        displayName: user.displayName,
        email: user.email,
        password: "",
        phone: user.phone,
        type: user.type,
        //photoURL: user.photoURL 
      });
      this.userToEdit = id;
      subscription.unsubscribe();
    })
  }
  onEditUserFormSubmit() {
    this.showSpinner = true;
    // Edit user in auth
    let authData = {}
    if (this.editUserForm.value.password != "") {
      authData = {
        id: this.userToEdit,
        mail: this.editUserForm.value.email,
        pass: this.editUserForm.value.password,
        name: this.editUserForm.value.displayName
      }
    } else {
      authData = {
        id: this.userToEdit,
        mail: this.editUserForm.value.email,
        name: this.editUserForm.value.displayName
      }
    }
    this.http.post<any>("https://us-central1-control-de-plantas.cloudfunctions.net/editUser", authData, this.httpOptions).subscribe(
      (result) => {
        if (result.updated) {
          // Upload Image
          if (this.filePath != "") {
            let ref = this.uploadService.getRefUpload(this.filePath);
            let task = this.uploadService.uploadFile(this.filePath, this.file);
            task.snapshotChanges().pipe(finalize(() => {
              ref.getDownloadURL().subscribe(
                (url) => { this.urlFile = url; },
                () => { },
                () => {
                  // Save user in Database
                  let data = {
                    displayName: this.editUserForm.value.displayName,
                    email: this.editUserForm.value.email,
                    phone: this.editUserForm.value.phone,
                    photoURL: this.urlFile,
                    type: this.editUserForm.value.type,
                  }
                  this.userService.updateUser(this.userToEdit, data);
                  this.toastr.success('', 'Usuario editado');
                  // Close modal
                  this.closeBtn.nativeElement.click();
                }
              )
            })).subscribe();
          } else {
            // Save user in Database
            let data = {
              displayName: this.editUserForm.value.displayName,
              email: this.editUserForm.value.email,
              phone: this.editUserForm.value.phone,
              type: this.editUserForm.value.type,
            }
            this.userService.updateUser(this.userToEdit, data);
            this.toastr.success('', 'Usuario editado');
            // Close modal
            this.closeBtn.nativeElement.click();
          }
        } else {
          this.toastr.error('Error al editar el usuario', result.error);
        }
      }
    )
  }

  // Delete User
  delete(id: string) {
    this.confirmDialogService.confirmThis("¿Segur@ que desea borrar este usuario?",
      () => this.deleteConfirmed(id), function () { }, "Borrar", "Cancelar"
    )
  }
  deleteConfirmed(userId: string) {
    let authData = {
      id: userId,
    }
    this.http.post<any>("https://us-central1-control-de-plantas.cloudfunctions.net/deleteUser", authData, this.httpOptions).subscribe(
      (result) => {
        if (result.error != {}) {
          this.userService.deleteUser(userId);
          this.toastr.success('', 'Usuario borrado');
        } else {
          this.toastr.error('Error al borrar al usuario', result.error);
        }
      }
    )
  }

  // Utils
  randomString(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }
  onSearchChange(search) {
    //console.log("Searching: ", search);

  }
  dismissModal() {
    this.filePath = "";
    this.file = "";
    this.showSpinner = false;
    this.newUserForm.reset();
  }
}
