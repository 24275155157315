import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../services/user/user.service';
import { User } from '../../models/user.model';
import { CumplimientoService } from '../../services/cumplimiento/cumplimiento.service';

@Component({
  selector: 'app-cumplimiento',
  templateUrl: './cumplimiento.component.html',
  styleUrls: ['./cumplimiento.component.css']
})
export class CumplimientoComponent implements OnInit {
  public informeForm: FormGroup;
  public users: User[];
  public months: Array<string>;
  public years: Array<number>;
  public showSpinner: boolean;
  public reportes: any;
  public params: any;
  public rep: Array<any>;
  public fallas: any;
  public gData: any[];
  public graph: boolean;
  public noreg: boolean;
  // Graph options
  view: any[] = [700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Meses';
  showYAxisLabel = true;
  yAxisLabel = 'Cumplimiento mensual';
  colorScheme = {
    domain: ['#0099CD']
  };

  constructor(private formBuilder: FormBuilder, private userService: UserService, private cumplimientoService: CumplimientoService) {
    this.informeForm = this.formBuilder.group({
      usuario: ['', Validators.compose([Validators.required])],
      year: ['', Validators.compose([Validators.required])],
    });
    this.showSpinner = false;
    var date = new Date();
    var currentYear = date.getFullYear();
    //set values for month dropdown
    this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    //set values for year dropdown
    this.years = [];
    for (var i = 0; i <= 10; i++) {
      this.years.push(currentYear - i);
    }
    // Initialise rep
    this.rep = [];
    this.fallas = [];
    this.gData = [];
    this.graph = false;
    this.noreg = false;
    //Object.assign(this, this.gData)
    this.gData = [...this.gData]
  }

  ngOnInit() {
    this.userService.getOperarios().subscribe(data => {
      this.users = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as User;
      })
    });
  }

  onInformeFormSubmit() {
    this.showSpinner = true;
    this.noreg = false;
    this.graph = false;
    this.params = {
      user: this.informeForm.value.usuario.id,
      year: this.informeForm.value.year,
    }
    let idCump = this.params.user + this.params.year;
    this.cumplimientoService.getCumplimiento(idCump).subscribe((data: any) => {
      if (data != undefined) {
        data.meses.forEach((m, i) => {
          //console.log(this.months[i], m.programados, m.enviados)
          let score = 0;
          if (m.programados != 0) {
            score = m.enviados / m.programados * 100;
          }
          this.gData.push({ name: this.months[i], value: score.toFixed(2) })
          this.gData = [...this.gData];
          this.graph = true;
          this.showSpinner = false;
        })
      } else { 
        this.showSpinner = false; 
        this.noreg = true;
      }
    })
  }

  onSelect(event) {
    console.log(event);
  }


}
