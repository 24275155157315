import { Component, OnInit } from '@angular/core';
import { Planta } from './../models/planta.model';
import { User } from '../models/user.model';
import { ListaReportes } from '../models/lista-reportes.model';
import { PlantaService } from './../services/planta/planta.service';
import { UserService } from '../services/user/user.service';
import { ReporteService } from '../services/reporte/reporte.service';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  plantas: Planta[];
  users: User[];
  reportes: ListaReportes[];
  faAngleRight = faAngleRight;
  showSpinner: boolean;

  constructor(private plantaService: PlantaService,
    private userService: UserService,
    private reporteService: ReporteService,
  ) { }

  ngOnInit() {
    this.plantaService.getPlantas().subscribe(data => {
      this.plantas = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Planta;
      })
    });
    this.userService.getOperarios().subscribe(data => {
      this.users = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as User;
      })
    });
    this.reporteService.getReportesLimit(10).subscribe(data => {
      this.reportes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
    });
  }

}
