import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PlantaService } from './../services/planta/planta.service';
import { ReporteService } from '../services/reporte/reporte.service';
import { Componente } from '../models/componente.model';
import { ListaReportes } from '../models/lista-reportes.model';
import { finalize } from 'rxjs/operators';
import { Planta } from './../models/planta.model';
import { ActivatedRoute } from "@angular/router";
declare var $: any;

import { faImage, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {
  public planta: any = "";
  public reporteInfo: any;
  public reporte: any;
  faImage = faImage;
  faCheck = faCheck;
  faTimes = faTimes;
  public viewMap: Boolean = false;
  public lat: Number;
  public lng: Number;
  public zoom: Number;
  public imageShown: string;

  constructor(private plantaService: PlantaService,
    private reporteService: ReporteService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    let plantaId = this.route.snapshot.paramMap.get("planta");
    let reporteId = this.route.snapshot.paramMap.get("reporte");
    this.plantaService.getPlanta(plantaId).subscribe((planta: Planta) => {
      this.planta = planta;
    });
    this.reporteService.getReporteInfo(reporteId).subscribe(data => {
      this.reporteInfo = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
      this.lat = this.reporteInfo[0].geotag.latitude;
      this.lng = this.reporteInfo[0].geotag.longitude;
      this.zoom = 16;
    });
    this.reporteService.getReporte(reporteId).subscribe(data => {
      this.reporte = data;
    })
  }

  showMap() {
    this.viewMap = true;
  }

  closeMap() {
    this.viewMap = false;
  }

  showPhoto(url: string) {
    this.imageShown = url;
  }
}
