import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private afStorage: AngularFireStorage) { }

  uploadFile(name: string, data: any) {
  	return this.afStorage.upload(name, data);
  }

  getRefUpload(name: string) {
  	return this.afStorage.ref(name);
  }
}
