import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mwl-demo-utils-calendar-header',
  styles: ['.btn-cta { background-color: #0099CD; border-color: #0099CD; color: #ffffff; }', 'h2 { color: #9D9D9D; font-size: 2rem; }'],
  template: `
    <div class="row text-center">
      <div class="col-md-4">
        <div class="btn-group">
          <button
            class="btn btn-cta"
            mwlCalendarPreviousView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Anterior
          </button>
          <button #todayBtn
            class="btn btn-outline-secondary"
            mwlCalendarToday
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Hoy
          </button>
          <button
            class="btn btn-cta"
            mwlCalendarNextView
            [view]="view"
            [(viewDate)]="viewDate"
            (viewDateChange)="viewDateChange.next(viewDate)"
          >
            Próximo
          </button>
        </div>
      </div>
      <div class="col-md-4">
        <h2>{{ viewDate | date:'MMMM, yyy' }}</h2>
      </div>
    </div>
    <br />
  `
})
export class CalendarHeaderComponent {
  @Input() view: string;
  @Input() viewDate: Date;
  @Input() locale: string = 'es-CO';
  @Output() viewChange: EventEmitter<string> = new EventEmitter();
  @Output() viewDateChange: EventEmitter<Date> = new EventEmitter();
}
