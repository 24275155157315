import { Component, OnInit } from '@angular/core';
import { ListaReportes } from '../../models/lista-reportes.model';
import { ReporteService } from '../../services/reporte/reporte.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Planta } from './../../models/planta.model';
import { PlantaService } from './../../services/planta/planta.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.css']
})
export class GeneralComponent implements OnInit {
  public informeForm: FormGroup;
  plantas: Planta[];
  public months: Array<string>;
  public years: Array<number>;
  public showSpinner: boolean;
  public reportes: any;
  public params: any;
  public rep: Array<any>;
  public fallas: any;
  public gData: any[];
  public graph: boolean;
  // Graph options
  view: any[] = [700, 400];
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = false;
  showXAxisLabel = true;
  xAxisLabel = 'Falla';
  showYAxisLabel = true;
  yAxisLabel = 'Cantidad';
  colorScheme = {
    domain: ['#0099CD']
  };

  constructor(
    private formBuilder: FormBuilder,
    private reporteService: ReporteService,
  ) { 
    this.informeForm = this.formBuilder.group({
      month: ['', Validators.compose([Validators.required])],
      year: ['', Validators.compose([Validators.required])],
    });
    this.showSpinner = false;
    var date = new Date();
    var currentYear = date.getFullYear();
    //set values for year dropdown
    this.years = [];
    for (var i = 0; i <= 10; i++) {
      this.years.push(currentYear - i);
    }
    //set values for month dropdown
    this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    // Initialise rep
    this.rep = [];
    this.fallas = [];
    this.gData = [];
    this.graph = false;
    //Object.assign(this, this.gData)
    this.gData = [...this.gData]
  }

  ngOnInit() {
  }

  onInformeFormSubmit() {
    // Reset variables
    this.fallas = [];
    this.gData = [];
    this.gData = [...this.gData]
    // Get new graph
    this.showSpinner = true;
    this.params = {
      month: this.informeForm.value.month,
      year: this.informeForm.value.year,
    }
    this.reporteService.getAllReportes(this.params).subscribe(data => {
      this.reportes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
      let rs = [];
      this.reportes.forEach((reporte, index) => {
        rs.push(reporte.reporte)
      })
      this.reporteService.getConsolidado(rs).subscribe(rep => {
        rep.forEach(r => {
          r.componentes.forEach(c => {
            c.equipos.forEach(e => {
              e.preguntas.forEach(p => {
                if (p.respuesta == 'false') {
                  //this.fallas.push({ componente: c.displayName, equipo: e.displayName, pregunta: p.pregunta })
                  this.fallas.push(p.pregunta)
                }
              })
            })
          })
        })
        let gFallas = this.groupFallas();
        let arrayfallas = Object.entries(gFallas);
        for (const [key, count] of arrayfallas) {
          this.gData.push({ name: key, value: count })
        }
        this.gData = [...this.gData];
        this.graph = true;
        this.showSpinner = false;
      })
    })
  }

  groupFallas() {
    // Create data for graph
    return this.fallas.reduce(function (prev, next) {
      prev[next] = (prev[next] + 1) || 1;
      return prev;
    }, {});
  }

  onSelect(event) {
    console.log(event);
  }

}
