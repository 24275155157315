import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UsersComponent } from './users/users.component';
import { PlantasComponent } from './plantas/plantas.component';
import { ComponentesComponent } from './componentes/componentes.component';
import { ReportesComponent } from './reportes/reportes.component';
import { InformesComponent } from './informes/informes.component';
import { VisitasComponent } from './informes/visitas/visitas.component';
import { HistorialComponent } from './historial/historial.component';
import { CalendarioComponent } from './calendario/calendario.component';
import { FallasComponent } from './informes/fallas/fallas.component';
import { IcomponentesComponent } from './informes/icomponentes/icomponentes.component';
import { GeneralComponent } from './informes/general/general.component';
import { ObservacionesComponent } from './informes/observaciones/observaciones.component';
import { CumplimientoComponent } from './informes/cumplimiento/cumplimiento.component';

import { AdminGuard } from  './admin/admin.guard';

const  routes:  Routes  = [
	{ path:  '', component:  DashboardComponent, canActivate: [AdminGuard] },
	{ path: 'login', component: HomeComponent },
	{ path: 'usuarios', component: UsersComponent, canActivate: [AdminGuard] },
	{ path: 'plantas', component: PlantasComponent, canActivate: [AdminGuard] },
	{ path: 'componentes', component: ComponentesComponent, canActivate: [AdminGuard] },
	{ path: 'reporte/:reporte/:planta', component: ReportesComponent, canActivate: [AdminGuard] },
	{ path: 'historial', component: HistorialComponent, canActivate: [AdminGuard] },
	{ path: 'informes', component: InformesComponent, canActivate: [AdminGuard] },
	{ path: 'informes/visitas', component: VisitasComponent, canActivate: [AdminGuard] },
	{ path: 'informes/fallas', component: FallasComponent, canActivate: [AdminGuard] },
	{ path: 'informes/componentes', component: IcomponentesComponent, canActivate: [AdminGuard] },
	{ path: 'informes/cumplimiento', component: CumplimientoComponent, canActivate: [AdminGuard] },
	{ path: 'informes/general', component: GeneralComponent, canActivate: [AdminGuard] },
	{ path: 'informes/observaciones', component: ObservacionesComponent, canActivate: [AdminGuard] },
	{ path: 'calendario', component: CalendarioComponent, canActivate: [AdminGuard] },

	// Redirect other routes to home.
	{ path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
