import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  constructor(private afs: AngularFirestore) { }

  getNotifications() {
    return this.afs.collection('notificaciones').snapshotChanges();
  }

  deleteNotification(id: string) {
    this.afs.collection('notificaciones').doc(id).delete();
  }
}
