import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';  
import { Observable, Subject } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {
	private subject = new Subject<any>();  

  	constructor() { }

  	confirmThis(message: string, yesFn: () => void, noFn: () => void, btnYes: string, btnNo: string) {  
        this.setConfirmation(message, yesFn, noFn, btnYes, btnNo);  
    }

    setConfirmation(message: string, yesFn: () => void, noFn: () => void, btnYes: string, btnNo: string) {  
        let that = this;  
        this.subject.next({  
            type: "confirm",  
            text: message,  
            yesFn: function () {  
                that.subject.next(); //this will close the modal  
                yesFn();  
            },  
            noFn: function () {  
                that.subject.next();  
                noFn();  
            },
            textYes: btnYes,
            textNo: btnNo
        });  
    }

    getMessage(): Observable<any> {  
        return this.subject.asObservable();  
    }
}
