import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { combineLatest } from 'rxjs'; 

@Injectable({
  providedIn: 'root'
})
export class ReporteService {

  constructor(private afs: AngularFirestore) { }

  getReportes() {
    return this.afs.collection('lista_reportes', ref => ref.orderBy('date', 'desc')).snapshotChanges();
  }

  getReportesPlantas() {
    let today = new Date();
    let date = new Date();
    date.setDate(today.getDate() - 10);
    return this.afs.collection('lista_reportes', ref => ref.where('date', '>=', date).orderBy('date', 'desc')).snapshotChanges();
  }

  getReportesLimit(limit: number) {
    return this.afs.collection('lista_reportes', ref => ref.orderBy('date', 'desc').limit(limit)).snapshotChanges();
  }

  getReporte(id: string) {
    return this.afs.collection('reportes').doc(id).valueChanges();
  }

  getReporteInfo(id: string) {
    return this.afs.collection('lista_reportes', ref => ref.where("reporte", "==", id)).snapshotChanges();
  }

  getReporteInforme(data: any) {
    let start = new Date(data.year+'-'+data.month+'-01')
    let end = new Date(data.year+'-'+data.month+'-31')
    return this.afs.collection('lista_reportes', ref => ref.where('planta', '==', data.planta).orderBy('date').startAt(start).endAt(end)).snapshotChanges();
  }

  getAllReportes(data: any) {
    let start = new Date(data.year+'-'+data.month+'-01')
    let end = new Date(data.year+'-'+data.month+'-31')
    return this.afs.collection('lista_reportes', ref => ref.orderBy('date').startAt(start).endAt(end)).snapshotChanges();
  }

  getConsolidado(data: any) {
    let consolidado = [];
    data.forEach(r => {
      consolidado.push(this.afs.collection('reportes').doc(r).valueChanges())
    })
    return combineLatest(consolidado);
  }
}
