import { Component, OnInit } from '@angular/core';
import { PlantaService } from './../services/planta/planta.service';
import { ReporteService } from '../services/reporte/reporte.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Planta } from './../models/planta.model';
import { ListaReportes } from '../models/lista-reportes.model';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-historial',
  templateUrl: './historial.component.html',
  styleUrls: ['./historial.component.css']
})
export class HistorialComponent implements OnInit {
  public informeForm: FormGroup;
  plantas: Planta[];
  public months: Array<string>;
  public years: Array<number>;
  public showSpinner: boolean;
  public reportes: any;
  public params: any;
  public nombrePlanta: string;
  faAngleRight = faAngleRight;

  constructor(private plantaService: PlantaService,
    private formBuilder: FormBuilder,
    private reporteService: ReporteService,
  ) { 
    this.informeForm = this.formBuilder.group({
      planta: ['', Validators.compose([Validators.required])],
      month: ['', Validators.compose([Validators.required])],
      year: ['', Validators.compose([Validators.required])],
    });
    this.showSpinner = false;
    this.nombrePlanta = "";
    var date = new Date();
    var currentYear = date.getFullYear();
    //set values for year dropdown
    this.years = [];
    for (var i = 0; i <= 10; i++) {
      this.years.push(currentYear - i);
    }
    //set values for month dropdown
    this.months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
  }

  ngOnInit() {
    this.plantaService.getPlantas().subscribe(data => {
      this.plantas = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as Planta;
      })
    });
  }

  onInformeFormSubmit() {
    this.showSpinner = true;
    this.params = {
      planta: this.informeForm.value.planta.id,
      month: this.informeForm.value.month,
      year: this.informeForm.value.year,
    }
    this.nombrePlanta = this.informeForm.value.planta.name;
    this.reporteService.getReporteInforme(this.params).subscribe(data => {
      this.reportes = data.map(e => {
        return {
          uid: e.payload.doc.id,
          ...e.payload.doc.data()
        } as ListaReportes;
      })
      this.showSpinner = false;
    })
  }

}
