import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog/confirm-dialog.module'
import { HttpClientModule } from '@angular/common/http';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { AgmCoreModule } from '@agm/core';

import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { UsersComponent } from './users/users.component';
import { PlantasComponent } from './plantas/plantas.component';
import { ComponentesComponent } from './componentes/componentes.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es-CO';
import { ReportesComponent } from './reportes/reportes.component';
import { InformesComponent } from './informes/informes.component';
import { HistorialComponent } from './historial/historial.component';
import { CalendarioComponent } from './calendario/calendario.component';
registerLocaleData(localeEs, 'es-CO');

import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DemoUtilsModule } from './components/demo-utils/module';
import { VisitasComponent } from './informes/visitas/visitas.component';
import { FallasComponent } from './informes/fallas/fallas.component';
import { GeneralComponent } from './informes/general/general.component';
import { IcomponentesComponent } from './informes/icomponentes/icomponentes.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CumplimientoComponent } from './informes/cumplimiento/cumplimiento.component';
import { ObservacionesComponent } from './informes/observaciones/observaciones.component';

var config = {
    apiKey: "AIzaSyDMP-F7j8G6d0fRKxLmQA2c3zJAbfUAeCs",
    authDomain: "control-de-plantas.firebaseapp.com",
    databaseURL: "https://control-de-plantas.firebaseio.com",
    projectId: "control-de-plantas",
    storageBucket: "control-de-plantas.appspot.com",
    messagingSenderId: "374292487724"
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent, 
    HeaderComponent,
    MenuComponent,
    UsersComponent,
    PlantasComponent,
    ComponentesComponent,
    ReportesComponent,
    InformesComponent,
    HistorialComponent,
    CalendarioComponent,
    VisitasComponent,
    FallasComponent,
    GeneralComponent,
    IcomponentesComponent,
    CumplimientoComponent,
    ObservacionesComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(config),
    AngularFireAuthModule,
    FontAwesomeModule,
    AngularFireDatabaseModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    FormsModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    AngularFireStorageModule,
    HttpClientModule,
    InternationalPhoneNumberModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDMP-F7j8G6d0fRKxLmQA2c3zJAbfUAeCs'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    DemoUtilsModule,
    NgxChartsModule,
  ],
  providers: [AngularFirestore, { provide: LOCALE_ID, useValue: 'es-CO' }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
